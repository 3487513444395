import { Fragment } from 'react';
import './App.css';
import MenuItem from './MenuItem';

function App() {
  const menu = [
    {
      menu_cat: "STARTER",
      menu_items: [
        {
          dishe: "Samosa",
          is_veg: true,
          price: "$5.20",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Chana Chat",
          is_veg: false,
          price: "$9.90",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Chana Bhatura",
          is_veg: true,
          price: "$12.20",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Bhelpuri",
          is_veg: true,
          price: "$9.99",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Chicken Kabab",
          is_veg: false,
          price: "$19.99",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
      ],
    },
    {
      menu_cat: "MAIN_COURSE",
      menu_items: [
        {
          dishe: "Samosa",
          is_veg: true,
          price: "$5.20",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Chana Chat",
          is_veg: false,
          price: "$9.90",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Chana Bhatura",
          is_veg: true,
          price: "$12.20",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Bhelpuri",
          is_veg: true,
          price: "$9.99",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
        {
          dishe: "Chicken Kabab",
          is_veg: false,
          price: "$19.99",
          img: "images/menu-item-thumbnail-01.jpg",
          alt: "samosa",
        },
      ],
    },
  ];

  return (
    <Fragment>
      {/* {menu.map((menu_category, i) => (
        <div>
           <MenuItem menuCategory={menu_category.menu_items}></MenuItem>
        </div>
      ))} */}
    </Fragment>
  );
}

export default App;
